<script setup>

import { ref, watch } from 'vue'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets'
import qs from 'qs'

const props = defineProps({
  organizationId: String
})

const table = ref(null)
const search = ref('')

const columns = [
  { title: 'Location Name', key: 'name' },
  { title: 'Overall', key: 'locationScore' },
  { title: 'Visibility', key: 'visibilityScore' },
  { title: 'Content', key: 'contentScore' },
  { title: 'Posts', key: 'postStats' },
  { title: 'Media', key: 'mediaStats' },
  { title: 'Calls', key: 'callStats' },
  { title: 'Moments', key: 'momentStats' },
  { title: 'Directions', key: 'directionStats' }
]

const tableSource = (params) => axios.get(
  `/ajax/organizations/${props.organizationId}/locations/table_index`,
  {
    params: { ...params, query: search.value?.trim() },
    paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
  }
).then((res) => res.data)

let searchTimeout = null

watch(search, () => {
  clearTimeout(searchTimeout)
  searchTimeout = setTimeout(() => {
    table.value.reload()
  }, 700)
})

const scoreClass = (score) => {
  let color
  switch (score) {
    case 'A':
      color = 'green'
      break
    case 'B':
      color = 'blue'
      break
    case 'C':
      color = 'yellow'
      break
    case 'D':
      color = 'orange'
      break
    default:
      color = 'red'
  }

  return `bg-${color}-100 text-${color}-800 text-xl font-medium px-2.5 py-0.5 rounded border border-${color}-400`
}

</script>

<template>
  <div class="grid grid-cols-1  gap-1 py-6">
    <div class="px-8">
      <input
        v-model="search"
        placeholder="Search"
        class="w-1/3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600
        focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
        dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
    </div>
    <VueSlimTables
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table px-8 py-2"
      :per-page="10">
      <template #cell:name="{ row }">
        <a :href="`/clients/dashboard/locations/${row['id']}/overview`">{{ row['name'] }}</a>
        <p>{{ row['placeIdFormattedAddress'] }}</p>
      </template>

      <template #cell:postStats="{ value }">
        <table style="width:100%">
          <tr>
            <th class="float-right">
              7d
            </th>
            <td>{{ value['lastWeek'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              30d
            </th>
            <td>{{ value['lastMonth'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              90d
            </th>
            <td>{{ value['lastQuarter'] }}</td>
          </tr>
        </table>
      </template>

      <template #cell:mediaStats="{ value }">
        <table style="width:100%">
          <tr>
            <th class="float-right">
              7d
            </th>
            <td>{{ value['lastWeek'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              30d
            </th>
            <td>{{ value['lastMonth'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              90d
            </th>
            <td>{{ value['lastQuarter'] }}</td>
          </tr>
        </table>
      </template>

      <template #cell:momentStats="{ value }">
        <table style="width:100%">
          <tr>
            <th class="float-right">
              7d
            </th>
            <td>{{ value['lastWeek'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              30d
            </th>
            <td>{{ value['lastMonth'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              90d
            </th>
            <td>{{ value['lastQuarter'] }}</td>
          </tr>
        </table>
      </template>

      <template #cell:callStats="{ value }">
        <table style="width:100%">
          <tr>
            <th class="float-right">
              7d
            </th>
            <td>{{ value['lastWeek'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              30d
            </th>
            <td>{{ value['lastMonth'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              90d
            </th>
            <td>{{ value['lastQuarter'] }}</td>
          </tr>
        </table>
      </template>

      <template #cell:directionStats="{ value }">
        <table style="width:100%">
          <tr>
            <th class="float-right">
              7d
            </th>
            <td>{{ value['lastWeek'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              30d
            </th>
            <td>{{ value['lastMonth'] }}</td>
          </tr>
          <tr>
            <th class="float-right">
              90d
            </th>
            <td>{{ value['lastQuarter'] }}</td>
          </tr>
        </table>
      </template>

      <template #cell:locationScore="{ value }">
        <div
          v-if="value"
          class="text-center">
          <span :class="scoreClass(value)">{{ value }}</span>
        </div>
      </template>

      <template #cell:visibilityScore="{ value }">
        <div
          v-if="value"
          class="text-center">
          <span :class="scoreClass(value)">{{ value }}</span>
        </div>
      </template>

      <template #cell:contentScore="{ value }">
        <div
          v-if="value"
          class="text-center">
          <span :class="scoreClass(value)">{{ value }}</span>
        </div>
      </template>
    </VueSlimTables>
  </div>
</template>
<style>
</style>
